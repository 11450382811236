import React, { FC, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { isBrowser } from 'utils/browser';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import HTag from 'components/common/HTag';
import { IExpertiseArticles } from './models';
import './ExpertiseArticles.scss';

const ExpertiseArticles: FC<IExpertiseArticles> = ({
  title: { regularText, boldText },
  articles,
  btn: { btnText },
  showingOfArticles,
}) => {
  let ARTICLES_INITIAL_NUM = 5;
  let ARTICLES_LOAD_NUM = 6;

  if (!isBrowser() || window.innerWidth < 768) {
    ARTICLES_INITIAL_NUM = 3;
    ARTICLES_LOAD_NUM = 3;
  }

  const [articlesShown, setArticlesShown] = useState<ArticleTypes.IArticle[]>(() => {
    return articles.slice(0, ARTICLES_INITIAL_NUM);
  });

  const loadMoreArticles = (startIdx, endIdx) => {
    setArticlesShown([...articlesShown, ...articles.slice(startIdx, endIdx)]);
  };

  const showingOf =
    showingOfArticles &&
    showingOfArticles
      .replace(
        '%num%',
        `<strong className="dt-expertise-articles__label-bold">${articlesShown.length}</strong>`
      )
      .replace(
        '%num%',
        `<strong className="dt-expertise-articles__label-bold">${articles.length}</strong>`
      );

  useEffect(() => {
    setArticlesShown(articles.slice(0, ARTICLES_INITIAL_NUM));
  }, [articles]);

  const CardBox = loadable(() => import('components/common/CardBox'));

  return (
    <section className="dt-expertise-articles" aria-label={`${regularText} ${boldText}`}>
      <HTag underline size={2} regularText={regularText} boldText={boldText} />

      <div className="dt-expertise-articles__row">
        <Row>
          {articlesShown.map((article, index) => {
            const isLarge = index === 0;

            return (
              <Col md={6} lg={isLarge ? 8 : 4} key={article.title}>
                <CardBox
                  title={article.title}
                  link={article.link}
                  intro={article.intro}
                  image={article.image}
                  imageAlt={article.imageAlt}
                  labelTop={
                    article.label
                      ? {
                          children: article.label,
                          type: 'primary',
                        }
                      : undefined
                  }
                  large={isLarge}
                />
              </Col>
            );
          })}
        </Row>
      </div>

      <div className="dt-expertise-articles__btn-holder">
        {showingOf ? (
          <span
            className="dt-expertise-articles__label"
            dangerouslySetInnerHTML={{ __html: showingOf }}
          />
        ) : null}

        {articlesShown.length === articles.length ||
        articles.length <= ARTICLES_INITIAL_NUM ? null : (
          <Button
            variant="outline-primary"
            className="dt-btn-mw-208"
            onClick={() => {
              loadMoreArticles(articlesShown.length, articlesShown.length + ARTICLES_LOAD_NUM);
            }}
          >
            {btnText}
          </Button>
        )}
      </div>
    </section>
  );
};

export default ExpertiseArticles;
